import React from 'react';

import Image, { StaticImageData } from 'next/image';

import { AppStoreButtons } from '../../../../components/Clickables/Buttons/AppStoreButtons/AppStoreButtons';
import { InternalLink } from '../../../../components/Clickables/InternalLink/InternalLink';
import { Grid } from '../../../../components/Grid/Grid';
import { Heading } from '../../../../components/Heading/Heading';
import { Source } from '../../../../components/Image/Source';
import { Text } from '../../../../components/Text/Text';
import { useAnalytics } from '../../../../contexts/AnalyticsContext/AnalyticsContext';
import { trackEvent } from '../../../../helpers/analytics/trackEvent';

interface Image {
  src: string;
  alt: string;
}

export interface HomePageFeatureSectionProps {
  heading: string;
  description: string;
  bannerImgMobile: StaticImageData;
  bannerImgTablet: StaticImageData;
  bannerImgDesktop: StaticImageData;
  bannerImgAltText: string;
  phoneImg: StaticImageData;
  phoneImageAlt: string;
  phoneRight: boolean;
  linkText: string | undefined;
  linkRoute: string | undefined;
  appStoreLinks?: boolean;
  eventName?: string;
}

export const HomePageFeatureSection = ({
  heading,
  description,
  bannerImgMobile,
  bannerImgTablet,
  bannerImgDesktop,
  bannerImgAltText,
  phoneImg,
  phoneImageAlt,
  phoneRight,
  linkText,
  linkRoute,
  appStoreLinks = false,
  eventName = '',
}: HomePageFeatureSectionProps) => {
  const { currentPage } = useAnalytics();

  const handleLinkClick = async ({ featureName }: { featureName: string }): Promise<void> => {
    void trackEvent({
      name: 'mykiss_cta_button_clicked',
      properties: {
        source: featureName,
        type: 'read-more',
        page: currentPage,
      },
    });
  };

  return (
    <div
      className="mx-auto grid max-w-screen-lg grid-cols-1 grid-rows-1 overflow-hidden sm:pb-20"
      data-testid="HomePageFeatureSection"
    >
      <div className="col-start-1 row-start-1">
        <div className="min-h-[300px] w-full object-cover">
          <picture>
            <Source media="(min-width: 990px)" src={bannerImgDesktop} />
            <Source media="(min-width: 600px)" src={bannerImgTablet} />
            <Image
              className="w-full object-cover"
              src={bannerImgMobile}
              placeholder="blur"
              alt={bannerImgAltText}
              sizes="(min-width: 1280px) 1280px, 100vw"
            />
          </picture>
        </div>

        <Grid className="mx-6 sm:mx-9 md:mx-5">
          <div
            className={`col-start-1 col-end-5 self-end pb-20 pt-[36%] sm:py-0 ${
              // the hard coded padding top pushes text down on a small screen so it isn't hidden behind the phone preview
              phoneRight
                ? 'sm:order-first sm:col-end-7 md:col-start-2'
                : 'sm:col-start-7 sm:col-end-13 md:col-end-12'
            }`}
          >
            <Heading as="h3" variant="md" className="mb-2.5 mt-2 sm:mt-6 md:mt-8">
              {heading}
            </Heading>
            <Text variant="md">{description}</Text>
            {linkText && linkRoute && (
              <InternalLink
                onClick={async () => handleLinkClick({ featureName: eventName })}
                to={linkRoute}
                variant="textPrimaryDarkBg"
              >
                {linkText}
              </InternalLink>
            )}
            {appStoreLinks && (
              <AppStoreButtons badgeWidth={133} badgeHeight={45} source={eventName} />
            )}
          </div>
        </Grid>
      </div>

      <div className="pointer-events-none col-start-1 row-start-1">
        <div className="mx-9 grid h-full grid-cols-1 sm:grid-cols-12 md:mx-5">
          <div
            className={`w-full justify-self-center pt-4 sm:self-end sm:pt-8 md:pt-20 ${
              phoneRight
                ? 'sm:order-last sm:col-start-7 sm:col-end-13 md:col-end-12'
                : 'sm:col-start-1 sm:col-end-7 md:col-start-2'
            }`}
          >
            <Image
              className="w-full object-cover"
              src={phoneImg}
              alt={phoneImageAlt}
              sizes="(min-width: 1680px) 640px,
                  (min-width: 990px) 41vw,
                  (min-width: 600px) 33vw,
                  100vw"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
